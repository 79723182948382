import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";

const RetirementByAgeSpecialInsured = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Aposentadoria por Idade do Segurado Especial"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          {
            page: "/aposentadoria-por-idade-segurado-especial",
            name: "Aposentadoria por Idade do Segurado Especial",
          },
        ]}
      >
        {isDesktop && <Aside />}

        <P.Article>
          <p>
            Para que o segurado especial consiga obter o benefício perante o
            órgão previdenciário deve estar exercendo atividade na condição de
            segurado especial no momento da solicitação do benefício, caso não
            esteja, terá o seu benefício indeferido, todavia, isso não quer
            dizer que perdeu o direito de se aposentar, mas apenas que terá que
            esperar um pouco mais de tempo.
          </p>
          <p>
            O segurado especial também tem direito a aposentadoria mista, que é
            aquela onde o beneficiário trabalhou em situação de segurado
            especial, mas agora está trabalhando na modalidade urbana, nessa
            situação, terá que comprovar as mesmas 180 contribuições, que
            poderão ser em regime especial somadas a períodos em regime urbano,
            mas que neste caso deverá contar com 65 anos de idade.
          </p>
          <p>
            Porém é muito importante que o autor tenha uma boa documentação
            comprobatória do seu período de atividade especial. Tudo poderá ser
            melhor esclarecido em uma eventual consultoria com um de nossos
            advogados.
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Aposentadoria por Idade do Segurado Especial"
    description="Orientação jurídica em Aposentadoria por Idade do Segurado Especial. Ajudamos a garantir seus direitos e a entender o processo de aposentadoria, com atendimento confiável e personalizado."
  />
);

export default RetirementByAgeSpecialInsured;
